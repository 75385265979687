import React, { useState, useEffect } from 'react'
import { find } from 'lodash'
import cx from 'classnames'

import {
  SmallClose,
  Minus,
  Plus
} from 'src/components/svgs'

import {
  removeFromCart as trackRemoveFromCart,
  addToCart as trackAddToCart,
} from 'src/components/analytics'

import {
  useUpdateItemsFromCart,
  useRemoveItemFromCart,
  useCardCost,
  client
} from 'src/context/siteContext'

const getAttribute = (collection: any, attribute: string) => (
  find(collection, { key: attribute })
)

export const BundledLineItem = ({ items, updating }) => {
  const updateItemsFromCart = useUpdateItemsFromCart()
  const cardCost = useCardCost()
  const [editing, setEdit] = useState(false)
  const removeFromCart = useRemoveItemFromCart()

  const updateQuantity = (qty: number, item: {}, adding: boolean) => {
    // const item = item.node
    console.log('item', item)
    updateItemsFromCart({id: item.node.id, quantity: qty, attributes: item.node.attributes})
    console.log(item)
    if (adding) {
      trackAddToCart([
        {
          // sku: variant.sku,
          title: item.node.merchandise.product.title,
          // variantTitle: variant.title,
          variant: item.node.merchandise,
          quantity: 1,
          price: parseInt(item.node.merchandise.price.amount).toFixed(2),
        }
      ])
    } else {
      trackRemoveFromCart([
        {
          // sku: variant.sku,
          title: item.node.merchandise.product.title,
          // variantTitle: variant.title,
          variant: item.node.merchandise,
          quantity: 1,
          price: parseInt(item.node.merchandise.price.amount).toFixed(2)
        },
      ])
    }
  }

  const bundleCost = () => {
    let itemCount = 0
    items.forEach(item => {
      itemCount += item.node.quantity
    })
    return itemCount * cardCost
  }

  const removeAllItems = () => {
    // console.log('remove an array of items')
    const allItems = []
    items.forEach(item => {
      allItems.push({
        id: item.node.id,
        quantity: 0,
      })
      trackRemoveFromCart([
        {
          // sku: item.variant.sku,
          title: item.node.merchandise.product.title,
          // variantTitle: variant.title,
          variant: item.node.merchandise,
          quantity: 1,
          price: parseInt(item.node.merchandise.price.amount).toFixed(2)
        },
      ])
    })
    console.log('allItems', allItems)
    updateItemsFromCart(allItems)
  }

  return (
    <div className='f x aic pr cart__single ba mb2 mt1'>
      <div className='x'>
        <div className='df x founders jcb p2'>
          <span className='s18 m0 p0'>{getAttribute(items[0].node.attributes, '__parentTitle').value}</span>
          <div className=''>
            ${bundleCost()}
          </div>
        </div>
        <div className='cart__single-list pt0 pl2 pr2 pb2'>
          {updating && (
            <div>
              Updating....?
            </div>
          )}
          {!updating && items.map((item, i) => (
            <div key={i} className='courier df jcb aic s10'>
              <span className='courier'>{item.node.merchandise.product.title}</span>
              <div className='df aic jcc'>
                <button className={cx('block button--text bg--theme qty__control no-style s24 f jcc aic founders cursor py05 aic', {
                  'none': !editing
                })} onClick={() => updateQuantity(item.node.quantity - 1, item, false)}><Minus /></button>
                ({item.node.quantity}) ({item.node.merchandise.price.amount})
                <button className={cx('qty__control button--text bg--theme no-style s1 block f jcc aic founders s24 cursor rel py05 jcc aic', {
                  'none': !editing
                })} onClick={() => updateQuantity(item.node.quantity + 1, item, true)}><Plus /></button>
              </div>
            </div>
          ))}
        </div>
        <div className='df bt bg--white jcb aic product__form-qty-wrapper'>
          <button className='button bn courier x' onClick={() => setEdit(!editing)}>
            <span>{editing ? 'Save' : 'Edit cards in this Deck'}</span>
          </button>
        </div>
        <button type='reset' className='pa bg--white no-style f jcc aic cart__single-close right top cb' onClick={() => removeAllItems()}>
          <SmallClose className='block' />
        </button>
      </div>
    </div>
  )
}
import React from 'react'
import cx from 'classnames'

import { Cart } from 'src/components/cart'
import { useStore, useCartItems, useToggleCart } from 'src/context/siteContext'

export const CartDrawer = ({ settings }) => {
  const toggleCart = useToggleCart()
  const { cartIsOpen } = useStore()
  const lineItems = useCartItems()
  return (
    <>
      <div
        className={cx('cart__drawer bl bcw z10 jcb right fdc df bg--theme top x pf', cartIsOpen && 'is-open')}
        id='container'
      >
        {lineItems ? (
          <Cart cartSettings={settings ? settings.cart : null} />
        ) : (
          <div>
            Cart is empty
          </div>
        )}
      </div>
      <div onClick={toggleCart} className={cx('cart__drawer-bg bg--off-white z10 o3 pf x y top left', cartIsOpen && 'is-open')} />
    </>
  )
}
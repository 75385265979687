// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-previews-tsx": () => import("./../../../src/pages/previews.tsx" /* webpackChunkName: "component---src-pages-previews-tsx" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/articleIndex.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-spices-tsx": () => import("./../../../src/templates/spices.tsx" /* webpackChunkName: "component---src-templates-spices-tsx" */)
}


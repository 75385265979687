const handleSlug = (type, slug) => {
  switch (type) {
    case 'page':
      return `/${slug}/`
    case 'location':
      return `/locations/${slug}/`
    case 'appointment':
      return `/appointments/${slug}/`
    case 'service':
      return `/services/${slug}/`
    case 'collection':
      return `/collections/${slug}/`
    case 'editorial':
      return `/article/${slug}/`
    case 'team':
      return `/team/${slug}/`
    case 'product':
      return `/products/${slug}/`
    default:
      return `/${slug}/`
  }
}


function toPlainText (blocks = []) {
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}

function lineBreak (text: string) {
  return text.replace(/[\r\n]+/g, '<br />')
}


module.exports = {
  handleSlug,
  lineBreak,
  toPlainText
}
import React, { useState, useEffect } from 'react'
import { encode, decode } from 'src/api/gid'
import { find } from 'lodash'

import {
  SmallClose,
  Minus,
  Plus
} from 'src/components/svgs'

import {
  useUpdateItemsFromCart,
  useRemoveItemFromCart,
  client
} from 'src/context/siteContext'

import {
  removeFromCart as trackRemoveFromCart,
  addToCart as trackAddToCart,
} from 'src/components/analytics'

export const LineItem = ({ id, quantity, updating, merchandise, attributes }: {
  id: string
  quantity: number
  updating: boolean
  merchandise: {
    title: string
    price: string
    image: string
    compareAtPrice: string
  }
  attributes: Array<{
    value: string
  }>
}) => {

  const  { price, sku, compareAtPrice, product } = merchandise
  const updateItemsFromCart = useUpdateItemsFromCart()

  const [stateQuantity, setQuantity] = useState(quantity)
  const removeFromCart = useRemoveItemFromCart()


  const updateQuantity = (qty: number) => {
    updateItemsFromCart({id, quantity: qty, attributes})
    const parentId = find(attributes, { key: '__parentId' })

    if (stateQuantity < qty) {
      trackAddToCart([
        {
          productId: parentId?.value,
          sku: sku,
          title: merchandise.product.title,
          variantTitle: merchandise.product.title,
          variant: merchandise,
          quantity: qty,
          price: parseInt(price.amount).toFixed(2),
        }
      ])
    } else {
      trackRemoveFromCart([
        {
          productId: parentId?.value,
          sku: merchandise.sku,
          title: merchandise.product.title,
          variantTitle: merchandise.product.title,
          variant: merchandise,
          quantity: qty,
          price: parseInt(price.amount).toFixed(2)
        },
      ])
    }
    setQuantity(qty)
  }

  // const itemImage = client.image.helpers.imageForSize(
  //   image,
  //   { maxWidth: 300, maxHeight: 300 }
  // )


  return (
    <div className='f x aic pr cart__single ba bc mb2 mt1'>
      <div className='x'>
        <div className='df x founders jcb p2'>
          <span className='ss18 m0 p0'>{product.title}</span>
          <div className=''>
            {compareAtPrice && (
              <span className='strike inline-block mr2'>
                ${parseFloat(compareAtPrice) * stateQuantity} 
              </span>
            )}
            <span>
              ${parseFloat(price.amount) * stateQuantity} 
            </span>
          </div>
        </div>
        <div className='df bt bc bg--white jcb aic product__form-qty-wrapper pl2 pr2'>
          <button
            className='block button--text qty__control no-style bg--theme s24 f jcc aic founders cursor py05 aic'
            onClick={() => stateQuantity === 1 ? null : updateQuantity(stateQuantity - 1)}><Minus /></button>
          <input className='cb courier ss18 p1 card-qty bn tc' type='number' value={stateQuantity}
            onChange={e => updateQuantity(parseInt(e.currentTarget.value, 10))}
            name='quantity' min='1'  />
          <button className='qty__control button--text no-style bg--theme s1 block f jcc aic founders s24 cursor rel py05 jcc aic' onClick={() => updateQuantity(stateQuantity + 1)}><Plus /></button>
          
        </div>
        <button type='reset' className='pa bg--white no-style bc f jcc aic cart__single-close right top cb' onClick={() => removeFromCart(id)}>
          <SmallClose className='block' />
        </button>
      </div>
    </div>
  )
}
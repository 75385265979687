import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Marquee } from 'dynamic-marquee'
import { find } from 'lodash'

import { Marqy } from 'marqy'
import 'marqy/styles.css'

import { handleSlug } from 'src/utils/handleSlug'
import { Image } from 'src/components/image'
import { Newsletter } from 'src/components/newsletter'

import {
  Instagram,
  Facebook,
  Twitter,
  Pinterest
} from 'src/components/svgs'

export const Footer = ({ menus, settings }) => {
  const [credits, setCredits] = useState(false)

  const {
    instagramText,
    instagramImages,
    social
  } = settings 

  const marqueeDom = React.createRef()

  const handleLinks = (links) => {
    return links.map((link, i) => (
      <li key={i} className='pb1'>
        {link._type === 'internalLink' && (
          <Link to={handleSlug(link.link._type, link.link.slug)}>{link.title}</Link>
        )}
        {link._type === undefined && (
          <Link to={link.link}>{link.title}</Link>
        )}
        {link._type === 'externalLink' && (
          <a href={link.link} target='_blank' rel='noopener nofollow'>{link.title}</a>
        )}
      </li>
    ))
  }

  const returnIcon = (icon) => {
    switch (icon) {
      case 'instagram':
        return <><span className='f jcc aic pr ta'><Instagram className='mr1' /></span>Instagram</>
      case 'twitter':
        return <><span className='f jcc aic pr ta'><Twitter className='mr1' /></span>Twitter</>
      case 'facebook':
        return <><span className='f jcc aic pr ta'><Facebook className='mr1' /></span>Facebook</>

    }
  }

  const companyMenu = find(menus, { handle: 'footer-company' })
  const shopMenu = find(menus, { handle: 'footer-shop' })
  const supportMenu = find(menus, { handle: 'footer-support' })

  const siteCredits = (
    <div className='ba pa footer__credits right bottom color--black bg--white bc'>
      <button className='pa df jcc aic right bg--transparent bc top' onClick={() => setCredits(false)}>
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
          <path d="M6.50706 2.13469L2.26434 6.35143" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M6.50702 6.35139L2.26438 2.13473" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
      <div className='row bb bc gutter--none'>
        <div className='col c7 br bc'>
          <span className='mono dib x s10'>Logo Mark</span>
        </div>
        <div className='col c7'>
          <a className='mono s10' href='https://sdcopartners.com/' target='_blank' rel='noopener nofollow'><span>SDCO Partners</span></a>
        </div>
      </div>
      <div className='row bb bc gutter--none'>
        <div className='col c7 br bc'>
          <span className='mono dib x s10'>Branding &amp; Packaging</span>
        </div>
        <div className='col c7'>
          <a className='mono s10' href='https://funeral.studio' target='_blank' rel='noopener nofollow'><span>Funeral</span></a>
        </div>
      </div>
      <div className='row bb bc gutter--none'>
        <div className='col c7 br bc'>
          <span className='mono dib x s10'>Brand Voice &amp; Copywriting</span>
        </div>
        <div className='col c7'>
          <a className='mono s10' href='https://www.emilygrubman.com/' target='_blank' rel='noopener nofollow'><span>Emily Grubman</span></a>
        </div>
      </div>
      <div className='row bb bc gutter--none'>
        <div className='col c7 br bc'>
          <span className='mono dib x s10'>Product Copywriting</span>
        </div>
        <div className='col c7'>
          <a className='mono s10' href='https://utkv6nyu.de/redir/clickGate.php?u=XMI6a9Ur&m=12&p=uuDGFy0ezF&t=33&splash=0&s=&url=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fkiefferkatz' target='_blank' rel='noopener nofollow'><span>Kieffer Katz</span></a>
        </div>
      </div>
      <div className='row bb bc gutter--none'>
        <div className='col c7 br bc'>
          <span className='mono dib x s10'>UX &amp; Web Design</span>
        </div>
        <div className='col c7'>
          <a className='mono s10' href='http://www.sam-faulkner.com/' target='_blank' rel='noopener nofollow'><span>Sam Faulkner</span></a>
        </div>
      </div>
      <div className='row bb bc gutter--none'>
        <div className='col c7 br bc'>
          <span className='mono dib x s10'>Photography</span>
        </div>
        <div className='col c7'>
          <a className='mono s10' href='http://www.petergarritano.com/' target='_blank' rel='noopener nofollow'><span>Peter Garritano</span></a>
        </div>
      </div>
      <div className='row  gutter--none'>
        <div className='col c7 br bc'>
          <span className='mono dib x s10'>Web Development</span>
        </div>
        <div className='col c7'>
          <a className='mono s10' href='https://ctrlaltdel.world' target='_blank' rel='noopener nofollow'><span>Kevin Green</span></a>
        </div>
      </div>
    </div>
  )
  
  return (
    <footer className='footer of--hidden'>
      <div className='mxa pr x'>
        <div className='f fw  aist footer__engage pr of--hidden'>
          <div className='bg--blue col x bt color--white bc--white'>
            <Marqy speed='0.3'>
              <div className='pr courier pt1 pb1 bb '>
                <div className=' df jcc y aic'>
                  {instagramText.map((iText, i) => (
                    <span key={i} className='caps courier mr3 ml3'>{iText}</span>
                  ))}
                  {instagramText.map((iText, i) => (
                    <span key={i} className='caps courier mr3 ml3'>{iText}</span>
                  ))}
                </div>
              </div>
            </Marqy>
            <div className='footer__social pt1 pb1'>
              <Marqy speed='0.2'>
                <div className="df jcb aic mb2">
                  {instagramImages.map((image, i) => (
                    <div key={i} className='footer__social-image pr'>
                      <Image key={i} className='x pa y pr m1' imageId={image.image.imageId} width={500} />
                    </div>
                  ))}
                </div>
              </Marqy>
            </div>
          </div>
          <div className='f x rel z1 jcb ais col x bt bc--white bl--800 color--white bg--blue'>
            <div className='footer__newsletter mxa p2 color--white f mt2 mb2 jcb fdc y pt2--800 pb2--800'>
              <h3 className='small mb05 mb3'>Piping hot news and promotions, served straight to your inbox</h3>
              <Newsletter />
            </div>
          </div>
        </div>
      </div>
      <div className='bg--blue color--white pb3--800 pt3--800 founders pt5--1000 bt bc--white'>
        <div className='container--xl mxa p2 pt3 outer p3--800 pb3--800'>
          <div className='row df fw jcb'>
            <div className='col c7 c3--800 mb3'>
              {shopMenu && (
                <>
                  <h5 className='caps founders medium pb1 pb3--800 ss18'>Shop</h5>
                  <ul>
                    {handleLinks(shopMenu.items)}
                  </ul>
                </>
              )}
            </div>
            <div className='col c7 c3--800 mb3'>
              {companyMenu && (
                <>
                  <h5 className='caps founders medium pb1 pb3--800 ss18'>Company</h5>
                  <ul>
                    {handleLinks(companyMenu.items)}
                  </ul>
                </>
              )}
            </div>
            <div className='col c7 c3--800'>
              {supportMenu && (
                <>
                  <h5 className='caps founders medium pb1 pb3--800 ss18'>Support</h5>
                  <ul>
                      {handleLinks(supportMenu.items)}
                  </ul>
                </>
              )}
            </div>
            <div className='col footer__social-links c7 c3--800'>
              <h5 className='caps founders medium pb1 pb3--800 ss18'>Social</h5>
              <ul>
                {social.map((social, i) => (
                  <li key={i} className='pb1'>
                    <a href={social.url} className='f jcs aic x' target='_blank' rel='noopener nofollow'>
                      {returnIcon(social.icon)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className='container--xl mxa p1 pr pl2--600 pb2--600 pr2--600 courier outer tc'>
          {credits && siteCredits}
          <div className='row'>
            <div className='col c8--600'>
              <div className='f jcb tc footer__terms'>
                <div className='x tc mr3--600'>
                  <Link className='ss12 o4' to='/terms-of-service'>Terms of Service</Link>
                </div>
                <div className='x tc mr3--800'>
                  <Link className='ss12 o4' to='/privacy-policy'>Privacy Policy</Link>
                </div>
              </div>
            </div>
            <div className='col tr--600 c6--600'>
              <button onClick={() => setCredits(true)} className='ss12 o4 button--text courier'>Site Credits</button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
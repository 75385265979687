// // @ts-ignore
// import shop from 'shopify-buy'

// export default shop.buildClient({
//   domain: "slater.store",
//   storefrontAccessToken: "b37ccdd79ec9c25620bc9d60f3b4c066",
// })


const request = async ({ headers, url }, query, variables) => {
  try {
    const response = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": '4e0468b1c7fa576fef539a59d54d5667'
      },
      body: JSON.stringify({
        query: minifyQuery(query),
        variables
      })
    });

    const data = await response.json();

    return data;
  } catch (e) {
    console.error(e);
  }
};

const minifyQuery = string => {
  return string
    .replace(/\s*#.*$/gm, "") // Remove GQL comments
    .replace(/\s+/gm, " ") // Minify spaces
    .trim();
};

export const createShopifyClient = client => {
  const version = client.version || "2023-07";
  const store = client?.store;
  const publicToken = client?.storefrontToken;

  if (!store) {
    throw error(500, "Missing Store");
  }

  if (!publicToken) {
    throw error(500, "Missing Token");
  }

  const url = new URL(`https://${store}/api/${version}/graphql.json`);

  return {
    fetch: async (query, input) => {
      const variables = {
        ...input
      };

      const response = await request({ url }, query, variables);

      return response?.data;
    }
  };
};

export const shopify = createShopifyClient({
  store: "eat-occo.myshopify.com",
  storefrontToken: "4e0468b1c7fa576fef539a59d54d5667"
});

export const globalID = (type, id) => {
  return `gid://shopify/${type}/${id}`;
};

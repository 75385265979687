import React, { useState, useEffect } from 'react'
import { find } from 'lodash'
import { PageLink } from 'src/components/link'
import {
  useCartTotals,
  useCartItems,
  useCheckout,
  useCartCount,
  useUpdateVariants,
  useUpdateItemsFromCart,
  useAddItemsToCart,
  useCardCost,
  useToggleCart
} from 'src/context/siteContext'
import BlockContent from '@sanity/block-content-to-react'


import { Image } from 'src/components/image'

import { Serializer } from 'src/utils/serializer'

import { PriceList } from 'src/components/product/priceList'

import {
  Close
} from 'src/components/svgs'

import { LineItem } from 'src/components/cart/lineItem'
import { BundledLineItem } from 'src/components/cart/bundledLineItem'

const getAttribute = (collection: any, attribute: string) => {
  return (
    find(collection, { key: attribute })
)}

function resolve(path, obj, separator='.') {
  var properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = resolve('attributes.0.value', obj.node)

    // const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const Cart = ({ cartSettings }) => {

  const lineItems = useCartItems()
  const [updating, setUpdating] = useState(true)
  const openCheckout = useCheckout()
  const toggleCart = useToggleCart()
  const updateVariants = useUpdateVariants()
  const updateItemsFromCart = useUpdateItemsFromCart()
  const addItemsToCart = useAddItemsToCart()
  const count = useCartCount()
  const cardCost = useCardCost()
  const { total } = useCartTotals()
  const [cartUpdating, setCartUpdating] = useState(false)
  const [pricing, setPricingList] = useState(false)

  const singleItems = lineItems && lineItems.length > 0 && lineItems?.filter(item => {
    return getAttribute(item?.node.attributes, '__productType').value === 'single'
  })

  const normalItems = lineItems && lineItems.length > 0 && lineItems?.filter(item => {
    return getAttribute(item?.node.attributes, '__productType').value === 'normal'
  })

  const bundledItems = lineItems && lineItems.length > 0 && lineItems?.filter(item => {
    return  getAttribute(item?.node.attributes, '__productType').value === 'grouped'
  })

  // const cardCount = bundledItems.length + singleItems.length

  const groupedItems = groupBy('attributes[0].value')
  const bundled = bundledItems ? groupedItems(bundledItems) : []

  useEffect(() => {
    // console.log('cart updates check if card cost is accurate?', lineItems)
    let shouldUpdate = false

    lineItems.forEach(item => {
      if (getAttribute(item.node.attributes, '__productType').value !== 'normal') {
        if (parseFloat(item.node.merchandise.price.amount) !== cardCost) {
          shouldUpdate = true
        }
      }
    })
    if (shouldUpdate && updating) {
      setCartUpdating(true)
      setUpdating(false)
      const clonedCart = []
      const oldCart = []
      const newCart = []
      lineItems.forEach(item => {

        let newId
        let update = false
        const attributes = []
        if (getAttribute(item.node.attributes, '__rawVariants')) {
          const parsedAtts = JSON.parse(getAttribute(item.node.attributes, '__rawVariants').value)
          console.log('parsedAtts', parsedAtts)
          parsedAtts.forEach(atts => {
            console.log(parseFloat(atts.variantPrice.amount), cardCost)
            if (parseFloat(atts.variantPrice.amount) === cardCost) {
              newId = atts.variantId
              update = true
            }
          })
          attributes.push({
            key: '__parentId', value: getAttribute(item.node.attributes, '__parentId').value
          })
          attributes.push({
            key: '__rawVariants', value: getAttribute(item.node.attributes, '__rawVariants').value
          })
        }
        attributes.push({
          key: '__productType', value: getAttribute(item.node.attributes, '__productType').value
        })
        if (getAttribute(item.node.attributes, '__parentTitle')) {
          attributes.push({
            key: '__parentTitle', value: getAttribute(item.node.attributes, '__parentTitle').value
          })
        }
        if (getAttribute(item.node.attributes, '__productCategory')) {
          attributes.push({
            key: '__productCategory', value: getAttribute(item.node.attributes, '__productCategory').value
          })
        }

        if (update) {
          newCart.push({
            merchandiseId: newId ? newId : item.node.id,
            quantity: item.node.quantity,
            attributes
          })

          oldCart.push({
            id: item.node.id,
            quantity: 0
          })
        }
      })

      updateVariants(oldCart, newCart).then(() => {
        // setUpdating(true)
        setCartUpdating(false)
      })
    }
  }, [lineItems, updating])

  return (
    <React.Fragment>
      <div className='y ofy--scroll'>
        <div className='ac rel cart__drawer-header tc'>
          {lineItems?.length > 0 && (
            <div className='p1 pt2--800 pb2--800'>
              <h4 className='h2'>Your Pantry</h4>
              {/* cardCount > 4 ? ( */}
                <span className='founders s18'>Start cooking</span>
              {/* }) : (
                <span className='founders s18'>Add {4 - cardCount} cards to get cooking</span>
              )*/ }
            </div>
          )}
          <button type='reset' className='m1 pa right top button--text cart__drawer-close bg--theme right top' onClick={() => toggleCart()}>
            <Close className='block' />
          </button>
        </div>
        {lineItems?.length > 0 ? (
          <div className='pr3 pl3'>
            {bundled && Object.keys(bundled).map((key, index) => (
              <div key={index}>
                <BundledLineItem items={bundled[key]} updating={cartUpdating} />
              </div>
            ))}
            {singleItems.length > 0 && (
              <>
                {singleItems.map((item: { id: string, quantity: number }) => (
                  <React.Fragment key={item.node.id + item.node.quantity}>
                    <LineItem {...item.node} updating={cartUpdating} />
                  </React.Fragment>
                ))}
              </>
            )}
            {normalItems.length > 0 && (
              <>
                {normalItems.map((item: { node: { id: string, quantity: number }} ) => (
                  <React.Fragment key={item.node.id + item.node.quantity}>
                    <LineItem {...item.node} updating={cartUpdating} />
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
        ) : (
          <div className='p3'>
            {cartSettings && (
              <>
                <BlockContent blocks={cartSettings.text} serializers={Serializer} />
                {cartSettings.cartImage && (<Image className='x' src={cartSettings.cartImage.url} imageId={cartSettings.cartImage.imageId} alt={cartSettings.cartImage.altText} />)}
              </>
            )}
          </div>
        )}
      </div>

      <div className='founders cart__drawer-footer pr'>
        <div className=''>
          {pricing && (
            <div onClick={() => setPricingList(!pricing)} className='pa pl3 pr3 cp product__pricing-table bottom  left x'>
            <span className='pa bottom z2 right product__pricing-button bg--white ba bc df jcc aic'>
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path d="M6.50706 2.13469L2.26434 6.35143" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.50702 6.35139L2.26438 2.13473" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </span>
            <PriceList />
          </div>
        )}
        </div>
        {/* <div className='bt bc x p2 pr3 pl3 df ss18 jcb aic'>
          <span>Price per Card: ${cardCost}</span>
          <button onClick={() => setPricingList(!pricing)} className='underline bn bg--transparent bg--theme button-tp button--none courier ss12'>See Pricing</button>
          </div> */}
        <div className='bt x bc pt2 pb1'>
          <div className='x jcb pl3 pr3 mb2'>
            {lineItems?.length > 0 && (
              <div className='x f ss18 jcb aic'>
                <span className=''>Subtotal</span>
                <span className=''>{total}</span>
              </div>
            )}
          </div>
          {lineItems?.length < 1 ? (
            <div className='f jcc aic bt bc bb bc rel x'>
              <PageLink className='button bg--theme-btn ml3 bn mr3 x'  onClick={() => toggleCart()} to='/collections/all'>
                <span>Add Cards to get cooking</span>
              </PageLink>
            </div>
          ): (
            <div className='pr3 pl3 bt bc bb'>
              <button onClick={() => openCheckout()} type='submit' className='button bn bcblue cw s18 button--h-black x bcb '>

              <span className='block'>Checkout</span>
                {/* count < 4 ? (
                  <React.Fragment>
                    {normalItems.length > 0 ? (
                      <span className='block'>Checkout</span>
                    ) : (
                      <span className=''>Add {4 - count} more cards to Checkout</span>
                    )}
                  </React.Fragment>
                ) : (
                  <span className='block'>Checkout</span>
                ) */}
              </button>
            </div>
          )}

          {cartSettings && (
            <div className='ac x founders tc ss18 db mt2'>
              <BlockContent blocks={cartSettings.shippingMessage} serializers={Serializer} />
            </div>
          )}
          {/* <span className='ac x founders tc ss18 db mb1 mt1'>Free shipping with every order</span> */}
        </div>
      </div>
    </React.Fragment>
  )
}
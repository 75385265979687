import React from 'react'
import {Helmet} from 'react-helmet'

// Global analytics fallback
export const track = (events = {}) => {
  if (typeof window === 'undefined') return

  for (let service in events) {
    const fn = window[service]
    if (!fn) continue
    fn.apply(this, events[service])
  }
}

export const pageView = pathname => {
  track({
    gtag: [
      'config',
      process.env.GATSBY_GOOGLE_ANALYTICS_PROPERTY,
      {
        page_path: pathname,
        linker: {
          domains: [
            'www.occo.com',
            'occo.com',
            'localhost:8000',
            'occo-production.netlify.app',
            'occo-staging.netlify.app',
            'occo-shop.netlify.app',
          ],
        },
      },
    ],
  })
}


export const viewCollection = (collection: {}, items = []) => {
  track({
    gtag: [
      'event',
      'view_item_list'
      // {
      //   items: items.map((item, index) => ({
      //     id: item.sku,
      //     name: item.title,
      //     list_name: collection.title,
      //     brand: 'occo',
      //     variant: get(item, 'variants[0].title'),
      //     list_position: index + 1,
      //     price: item.cents * 100,
      //   })),
      // },
    ],
  })
}

export const viewProduct = (item = {}) => {
  track({
    gtag: [
      'event',
      'view_item',
      {
        items: [{
          id: item.sku,
          name: item.title,
          brand: 'Occo',
          variant: item.variantTitle,
          price: item.price,
        }],
      },
    ],
  })
}

export const addToCart = (items = [] as any[]) => {
  track({
    gtag: [
      'event',
      'add_to_cart',
      {
        items: items.map(item => ({
          id: item.productId,
          sku: item.sku,
          name: item.title,
          brand: 'Occo',
          variant: item.variant,
          quantity: item.quantity || 1,
          price: item.price,
        })),
      },
    ],
  })
}

export const subscribedToNewsletter = (email = '' as string) => {
  track({
    gtag: [
      'event',
      'subscribe_newsletter',
      {
        email: email
      },
    ],
  })
}

export const removeFromCart = (items = [] as any[]) => {
  track({
    gtag: [
      'event',
      'remove_from_cart',
      {
        items: items.map(item => ({
          id: item.sku,
          name: item.title,
          brand: 'Occo',
          variant: item.variant,
          quantity: item.quantity || 1,
          price: item.price,
        })),
      },
    ],
  })
}


//
// === Group all script tags here` ===
//

export const Analytics = () => {
  return (
    <React.Fragment>
      {/* HEAD */}
      <Helmet>
        {/* GTM */}
        <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.GATSBY_GTM_PROPERTY}');
        `}</script>
        {/* GA */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ANALYTICS_PROPERTY}`}
        />
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.GATSBY_GOOGLE_ANALYTICS_PROPERTY}');
        `}</script>
        <script async type="text/javascript" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=WRQmUE"></script>
      </Helmet>

      {/* BODY */}
      {/* GTM */}
      <noscript>
        <iframe
          title='gtm noscript'
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTM_PROPERTY}`}
          height="0"
          width="0"
          className='db'
        />
      </noscript>
    </React.Fragment>
  )
}
import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import Helmet from 'react-helmet'
import tighpo from 'tighpo'

import { Header } from 'src/components/header'
import { Footer } from 'src/components/footer'
import { Analytics } from 'src/components/analytics'
import { SwitchTransition, Transition } from 'react-transition-group'
import { CartDrawer } from 'src/components/cartDrawer'
import { PasswordWrapper } from './password'

import { useStore } from 'src/context/siteContext'


const TRANSITION_DURATION = 500;
const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

// import 'src/styles/vendor.css'
import 'src/styles/main.scss'

const Layout = ({ children, siteMetadata, location, pageContext }: { children: any }) => {

  const [shrink, setShrink] = useState(false)
  const { theme, page } = useStore()

  // Render documentation for CMS minus header/footer experience
  if (pageContext.matchPath && (pageContext.matchPath.includes('docs') || pageContext.matchPath.includes('carts') || pageContext.matchPath.includes('previews'))) {
    return (
      <div>
        {children}
      </div>
    )
  }

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      const trigger = 120
      const doc = document.documentElement
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
      if (top > trigger) {
        setShrink(true)
      } else {
        setShrink(false)
      }
    })
  }, [])

  useEffect(() => {
    tighpo('spaghetti', function () {
      const style = document.createElement('style')
      document.body.appendChild(style)
      style.sheet.insertRule('html, body { cursor: url(https://spaghet.now.sh), auto !important; }')
    })
  }, [])

  const disableTransition =
    (location.state && location.state.disableTransition) || false

  return (
    <React.Fragment>
      <Helmet title='Occo' />
        <div className={cx('site__background', {
          'shrink': shrink
        }, theme, page)}>
          <Header {...pageContext} menus={pageContext.menus} settings={pageContext.settings && pageContext.settings.content} />
          <CartDrawer settings={pageContext.settings && pageContext.settings.content} />
          {/* 
          
            Smooth transition credits to Ian Williams: https://github.com/dictions
          
          */}
          {/account/.test(location.pathname) || disableTransition ? (
            <div>
              {children}
              <Footer menus={pageContext.menus}  settings={pageContext.settings.content} />
            </div>
            ) : (
            <SwitchTransition>
              <Transition
                key={location.pathname}
                mountOnEnter={true}
                unmountOnExit={true}
                appear={true}
                timeout={TRANSITION_DURATION}>
                  {status => (
                    <div
                      style={{
                        ...TRANSITION_STYLES.default,
                        ...TRANSITION_STYLES[status],
                      }}>
                    {children}
                    {pageContext.settings && (<Footer menus={pageContext.menus} settings={pageContext.settings.content} />)}
                    </div>
                  )}
              </Transition>
            </SwitchTransition>
          )}
        </div>
      <Analytics />
    </React.Fragment>
  )
}

export default Layout
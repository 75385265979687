import React, { useEffect } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'

import {
  LinkArrow
} from 'src/components/svgs'

import { Image } from 'src/components/image'
import { handleSlug } from 'src/utils/utils'

const handleStyle = (props: any) => {
  const { style } = props.node
  switch(style) {
    case 'small':
      return <p className='ss12 mt1 medium caps'>{props.children}</p>
    case 'h1-l':
      return <h2 className='h1 mb2'>{props.children}</h2>
    case 'large':
      return <p className='s24 s30--800 mb2'>{props.children}</p>
    case 'h5':
      return <h5 className='caps w100'>{props.children}</h5>
    case 'h2':
      return <h2 className='pb1 mb1--600'>{props.children}</h2>
    default:
      return BlockContent.defaultSerializers.types.block(props)
  }
}

export const Serializer = {
  types: {
    block: (props: {
      children: any[]
      node: {
        style?: string
      }
    }) => {
      return (
        <React.Fragment>
          {handleStyle(props)}
        </React.Fragment>
      )
    },
    imageModule: (props: {
      children: any[]
      node: {
        image: {
          imageId: string
          altText?: string
        }
      }
    }) => {
      return (
        <div>
          <Image className='x' imageId={props.node.image.imageId} src={props.node.image.url} alt={props.node.image.altText} />
        </div>
      )
    }
  },
  marks: {
    internalLink: (props: {
      children: any
      mark: any
    }) => {
      if (props.mark.reference) {
        return (
          <>
          {props.mark.reference.content && (
            <Link className={cx('', {
              'ls1 button di auto s14 mt1 mb1 mt4--600': props.mark.linkType === 'button',
              'small underline bold ls1': props.mark.linkType === 'text',
              'link-underline ws--nowrap mt1 caps founders mt1 dib s16': props.mark.linkType === 'text-arrow'
            })} role={props.mark.linkType} to={handleSlug(props.mark.reference._type, props.mark.reference.content.main.slug.current)}>
              <span>{props.children}</span>
              {props.mark.linkType === 'text-arrow' && ( <>&nbsp;<LinkArrow /></>)}
            </Link>
          )}
          </>
        )        
      } else {
        return <span>{props.children}</span>
      }
    },
    externalLink: (props: {
      children: any
      mark: any
    }) => {
      return (
          <a className={cx('small bold underline', {
            'f jcs aic': props.mark.linkType === ('text-arrow' || undefined)
          })} href={props.mark.url} target='_blank'>
          {props.children}
          </a>
      )
    },
    founders: (props: {
      children: any
    }) => {
      return (
        <span className='founders'>{props.children}</span>
      )
    },
    tick: (props: {
      children: any
    }) => (
      <span className='tick'>{props.children}</span>
    ),
    em: (props: {
      children: any
    }) => (
      <span className='italic'>{props.children}</span>
    )
  }
}

const ArticleWrapper = ({children}) => {
  return (
    <div className='article__wrapper container--800 pl2 pr2 pl5--800 pr5--800 mxa'>
      {children}
    </div>
  )
}


export const EditorialSerializer = {
  types: {
    block: (props: {
      children: any[]
      node: {
        style?: string
      }
    }) => {
      return (
        <ArticleWrapper>
          {handleStyle(props)}
        </ArticleWrapper>
      )
    },
    spacer: (props: {
      children: any[]
    }) => {
      return (
        <ArticleWrapper>
          <div className={props.node.spacerType} />
        </ArticleWrapper>
      )
    },
    articleProduct: (props: {
      children: any[]
    }) => {
      const { product } = props.node
      return (
        <ArticleWrapper>
          <div className='pt2 pb2'>
            <div className='row x df--800 jcb aic gutter--none bt bb bc pt1 pb1 '>
              <div className='col c14 c9--800'>
                <div className='df x jcs aic'>
                  <Image className='db article__product-image object-fit x' width={400} imageId={product.imageId} alt={product.title} />
                  <div className='x ml2 db'>
                    <h4 className='s24 mb1 founders'>{product.title}</h4>
                    <Link to={`/products/${product.slug}`} className='founders hide--800 link-underline link-arrow caps'>Shop Now <LinkArrow /></Link>
                  </div>
                </div>
              </div>
              <div className='col c4--800 tr'>
                <Link to={`/products/${product.slug}`} className='founders link-underline show--800 link-arrow caps'>Shop Now <LinkArrow /></Link>
              </div>
            </div>
          </div>
        </ArticleWrapper>
      )
    },
    articleImages: (props: {
      children: any[]
    }) => {
      return (
        <div className='mt4 mb4 container--1200 mxa p2'>
          <div className='row gutter--none'>
            {props.node.image.map((img, i) => {
              return (
                <div key={i} className={cx('col c14', {
                  'c7--800': props.node.image.length === 2,
                })}>
                  <Image className='x' imageId={img.image.imageId} alt={img.caption} />
                  <span>{img.caption}</span>
                </div>
              )
            })}
          </div>
        </div>
      )
    },
    imageModule: (props: {
      children: any[]
      node: {
        image: {
          imageId: string
          altText?: string
        }
      }
    }) => {
      return (
        <div>
          <Image className='x' imageId={props.node.image.imageId} src={props.node.image.url} alt={props.node.image.altText} />
        </div>
      )
    }
  },
  list: props => (
    <ArticleWrapper>
      {(props.type === 'bullet' ? (
        <ul>{props.children}</ul>
      ) : (
        <ol>{props.children}</ol>
      ))}
    </ArticleWrapper>
  ),
  marks: {
    internalLink: (props: {
      children: any
      mark: any
    }) => {
      if (props.mark.reference) {
        return (
          <>
          {props.mark.reference.content && (
            <Link className={cx('', {
              'ls1 button di auto s14 mt1 mb1 mt4--600': props.mark.linkType === 'button',
              'small underline bold ls1': props.mark.linkType === 'text',
              'link-underline ws--nowrap mt1 caps founders mt1 dib s16': props.mark.linkType === 'text-arrow'
            })} role={props.mark.linkType} to={handleSlug(props.mark.reference._type, props.mark.reference.content.main.slug.current)}>
              <span>{props.children}</span>
              {props.mark.linkType === 'text-arrow' && ( <>&nbsp;<LinkArrow /></>)}
            </Link>
          )}
          </>
        )        
      } else {
        return <span>{props.children}</span>
      }
    },
    externalLink: (props: {
      children: any
      mark: any
    }) => {
      return (
          <a className={cx('small bold underline', {
            'f jcs aic': props.mark.linkType === ('text-arrow' || undefined)
          })} href={props.mark.url} target='_blank'>
          {props.children}
          </a>
      )
    },
    founders: (props: {
      children: any
    }) => {
      return (
        <span className='founders'>{props.children}</span>
      )
    },
    tick: (props: {
      children: any
    }) => (
      <span className='tick'>{props.children}</span>
    ),
    em: (props: {
      children: any
    }) => (
      <span className='italic'>{props.children}</span>
    )
  }
}

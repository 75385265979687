import React, { useEffect, useState, useRef } from 'react'
import cx from 'classnames'

import { Link } from 'gatsby'
import { motion, useCycle } from 'framer-motion'
import { find } from 'lodash'
import { Promo } from './promo'

import { handleSlug } from 'src/utils/handleSlug'

import {
  Logo,
  LogoFlat,
  Instagram,
  Close,
  Facebook,
  Twitter,
  Cart,
  Pinterest
} from 'src/components/svgs'

import { useCartCount, useToggleCart, useStore } from 'src/context/siteContext'

const sidebar = {
  open: {
    transform: `translateX(0px)`,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
      restDelta: 2
    }
  },
  closed: {
    transform: `translateX(-500px)`,
    transition: {
      delay: 0.3,
      stiffness: 400,
      damping: 40,
      type: "spring"
    }
  }
}

const bottomVariant = {
  open: {
    transform: `translateY(0px)`,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
      restDelta: 2
    }
  },
  closed: {
    transform: `translateY(200px)`,
    transition: {
      delay: 0.3,
      stiffness: 400,
      damping: 40,
      type: "spring"
    }
  }
}

const headerBg = {
  open: {
    opacity: 1
  },
  closed: {
    opacity: 0
  }
}

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};


const linkVariant = {
  open: {
    y: 0,
    x: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 10,
    x: -10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};


const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    stroke="currentColor"
    strokeLinecap="round"
    {...props}
  />
);

export const Header = ({ promo, menus, settings }) => {
  const [navOpen, setNavOpen] = useState(false)
  const [shrink, setShrink] = useState(false)
  const drawer = useRef()
  const [isOpen, toggleOpen] = useCycle(false, true)
  const [headerPush, toggleHeaderPush] = useState(true)

  const count = useCartCount()
  const toggleCart = useToggleCart()

  const returnIcon = (icon) => {
    switch (icon) {
      case 'instagram':
        return <Instagram />
      case 'twitter':
        return <Twitter />
      case 'facebook':
        return <Facebook />

    }
  }


  const generalMenu = find(menus, { handle: 'header-general' })
  const shopMenu = find(menus, { handle: 'header-shop' })
  const supportMenu = find(menus, { handle: 'header-support' })

  const handleLinks = (links) => {
    return links.map((link, i) => (
      <motion.div
        variants={linkVariant} key={i} className=''>
        {link._type === 'internalLink' && (
          <Link className='s22 js-link founders db' to={handleSlug(link.link._type, link.link.slug)}>{link.title}</Link>
        )}
        {link._type === undefined && (
          <Link className='s22 js-link founders db' to={link.link}>{link.title}</Link>
        )}
        {link._type === 'externalLink' && (
          <a className='s22 js-link founders db' href={link.link} target='_blank' rel='noopener nofollow'>{link.title}</a>
        )}
      </motion.div>
    ))
  }


  return (
    <>
      {promo && promo.active && (
        <Promo {...promo} hidePromo={() => toggleHeaderPush(false)} />
      )}
      <header className={cx('psy fw z10 x header df jcb aic ', {
        'active-promo': headerPush
      })}>
        <div className='x p1 pl2--800 pr2--800 header__main bc outer psy top color--blue'>
          <div className='f x jcb aic'>
            <motion.div
              initial={false}
              animate={isOpen ? 'open' : 'closed'}>
              <button onClick={() => toggleOpen()} aria-label='open shop navigation' className='ml1 header__toggle df jcs aic button--none bg--transparent bn s18'>
                <svg width="20" height="20" fill='currentColor' viewBox="0 0 20 20">
                  <Path
                    variants={{
                      closed: { d: "M 1 4.5 L 14 4.5" },
                      open: { d: "M 2 12.5 L 11 4.5" }
                    }}
                  />
                  <Path
                    variants={{
                      closed: { d: "M 1 12.346 L 14 12.346" },
                      open: { d: "M 2 4.5 L 11 12.346" }
                    }}
                  />
                </svg>
                <span className='show--600'>Shop</span>
              </button>
            </motion.div>
            <div className='header__logo pa top left'>
              <Link to='/' className='color--blue'>
                <Logo className='header__logo-large' />
                <LogoFlat className='header__logo-flat' /> 
              </Link>
            </div>
            <div>
              <button aria-label='cart' className='p0 s18 founders button--text  mr1' onClick={() => toggleCart()}>
                <span className='df jcc aic'>
                  <span className='show--600'>Your Pantry</span> <Cart className='header__cart-bag pr' /> {count > 0 && (
                    <div className='header__cart-num bg--theme-btn pr'>
                      <span className='courier pa x y fill top left s12'>{count}</span>
                    </div>
                  )}
                </span>
              </button>
            </div>
          </div>
        </div>
      </header>
      
      <motion.nav 
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        onClick={() => toggleOpen()} 
        className={cx('pa z10', isOpen && 'x y top  left')}
        ref={drawer}>
        <motion.div variants={sidebar} className={cx('header__mobile top df pf color--black bg--lite-yellow z9 y')}>
          <div className='mt0 header__mobile-top x'>
            <div className='mt2 mb4'>
              <div className='pl2'>
                <motion.div variants={variants}>
                  <div>
                    <button onClick={() => console.log('click')} aria-label='close shop navigation' className='m0 p0 df header__toggle-close jcs aic button--none bg--lite-yellow color--black bn s18'>
                      <Close />
                      <span className='show--600'>Shop</span>
                    </button>
                  </div>
                </motion.div>
              </div>
            </div>
            <div className='pl2 pl5--800 pt2 pb1'>
              <motion.div variants={variants}>
                <motion.div
                  variants={linkVariant}>
                  <Link to='/collections/all' className='s36 serif'>Shop All</Link>
                </motion.div>
              </motion.div>
            </div>
            <div className='pl2 pl5--800 mt1'>
              <motion.div variants={variants}>
                {shopMenu && (
                  <>
                  {handleLinks(shopMenu.items)}
                  </>
                )}
              </motion.div>
            </div>
            <div className='bt x mt4 mb4 db' />
            <div className='pl2 pl5--800 mt1'>
              <motion.div variants={variants}>
                {generalMenu && (
                  <>
                  {handleLinks(generalMenu.items)}
                  </>
                )}
              </motion.div>
            </div>
          </div>
          {/* <div className='pa right header__mobile-sticker'>
            <div className='pa x y df jcc aic tc top left'>
              <span className='caps color--white s14'>Free Shipping on all orders</span>
            </div>
            <svg width="193" height="194" viewBox="0 0 193 194" fill="none">
              <path d="M25.6503 79.6203C48.7121 70.5546 67.7683 51.8425 76.342 26.8846C95.618 42.2642 121.321 49.3736 147.154 44.235C143.369 68.6803 149.949 94.4404 167.275 114.321C144.213 123.387 125.157 142.099 116.583 167.057C97.3073 151.677 71.6038 144.568 45.771 149.706C49.6183 125.195 42.9095 99.4396 25.6503 79.6203Z" fill="#130C80" />
            </svg>
          </div> */}

          <motion.div variants={bottomVariant} className='pa bottom x bt bg--gold'>
            <div className='p2 pt4--800 pb4--800 pl5--800 pr5--800'>
              <div className='df jcb'>
                {settings && settings.social.map((social, i) => (
                  <div key={i}>
                    <a href={social.url} className='f jcs aic x' target='_blank' rel='noopener nofollow'>
                      {returnIcon(social.icon)}
                    </a>
                  </div>
                ))}
              </div>
              <div className='df mt1 mt3--800 jcb'>
                {supportMenu && supportMenu.items.map((link, i) => (
                  <a className='courier s14'  href={link.link} key={i} target='_blank' rel='noopener nofollow'>{link.title}</a>
                ))}
              </div>
            </div>
          </motion.div>
        </motion.div>

        <motion.div variants={headerBg} className={cx('header__nav-bg pf x y z8 top left')}>
          <div className='pa x y top left' />
        </motion.div>
      </motion.nav>
    </>
  )
}

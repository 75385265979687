import React from 'react'

export const PriceList = () => {
  return (
    <div className='row bg--white product__price-list  pr gutter--none'>
      <div className='col c7 bb p1'>
        <span className='courier s12 p2 di'># of Cards</span>
      </div>
      <div className='col c7 bb bl p1'>
        <span className='courier s12 p2 di'>$ per Card</span>
      </div>
      <div className='col c7 product__pricing-single bb'>
        <span className='courier s12 p2 di'>4-7</span>
      </div>
      <div className='col c7 product__pricing-single bb bl'>
        <span className='courier s12 p2 di'>$5</span>
      </div>
      <div className='col c7 product__pricing-single bb'>
        <span className='courier s12 p2 di'>8-15</span>
      </div>
      <div className='col c7 product__pricing-single bb bl'>
        <span className='courier s12 p2 di'>$4.75</span>
      </div>
      <div className='col c7 product__pricing-single bb'>
        <span className='courier s12 p2 di'>16+</span>
      </div>
      <div className='col c7 product__pricing-single bb bl'>
        <span className='courier s12 p2 di'>$4.5</span>
      </div>
    </div>
  )
}
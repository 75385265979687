import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import { motion, AnimatePresence } from 'framer-motion'

import {
  Close
} from 'src/components/svgs'

export const Promo = ({ active, content, hidePromo }: {
  active: boolean
  content?: string
  hidePromo: () => void
}) => {
  const [activePromo, setActivePromo] = useState(true)
  useEffect(() => {
    if (!activePromo) {
      hidePromo()
    }
  }, [activePromo])
  useEffect(() => {
    const body = document.querySelector('body')
    if (activePromo) {
      body.classList.add('promo-active')
    } else {
      body.classList.remove('promo-active')
    }
  }, [activePromo])

  const showPromo = activePromo && (
    <div className='x pr bg--theme-btn pr header__promo courier s12 s14--800 color--white tc'>
      <span className='s12 s14--800 ' dangerouslySetInnerHTML={{ __html: content }} />
      <button onClick={() => setActivePromo(false)} className='right pa y bn bg--theme-btn top'>
        <Close />
      </button>
    </div>
  )

  return (
    <>
      {showPromo}
    </>
  )
}

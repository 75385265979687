const handleSlug = (type: string, slug: string) => {
  switch (type) {
    case 'page':
      return `/${slug}`
    case 'spiceIndex':
      return `/spices`
    case 'location':
      return `/locations/${slug}`
    case 'product':
      return `/products/${slug}`
    case 'collection':
      return `/collections/${slug}`
    case 'editorial':
      return `/article/${slug}`
    default:
      return `/${slug}`
  }
}

module.exports = {
  handleSlug
}
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

  
import React from "react"
import { StoreContextProvider } from "src/context/siteContext"
import * as Sentry from '@sentry/browser'
import getViewportPosition from 'magic-tricks/lib/getViewportPosition'


import {pageView} from './src/components/analytics'

//
// === Route Change ===
//

export const onRouteUpdate = ({location}) => {
  // Track page views
  pageView(location.pathname)
}

//
// === Scroll Logic ===
//

let scrollUpdateCount = 0

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual'
}

const scrollToTop = () => {
  const top = getViewportPosition()

  // Scroll to just below banner on desktop
  if (top >= 32) {
    global.scrollTo(0, 38 + 1)
  }
}

let defaultTransition = 400

export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition
}) => {

  const isAccount = location.pathname.indexOf('/account') === 0
	const isCollection =
		location.pathname.indexOf('/collections') === 0

	const routeTransition = isAccount || isCollection ? 0 : defaultTransition

	if (location.action === "PUSH") {
		if (routeTransition) {
			setTimeout(scrollToTop, routeTransition + 200)
		} else {
			scrollToTop()
		}
	} else {
		const savedPosition = getSavedScrollPosition(location);
		window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 600);
	}
	return false;
};

// Optional Config Sentry
// Sentry.init({dsn: process.env.GATSBY_SENTRY_DSN});

export const wrapRootElement = ({ element }) => (
  <StoreContextProvider>{element}</StoreContextProvider>
)
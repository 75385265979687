import React, { useState } from 'react'
import { subscribe } from 'klaviyo-subscribe'


import { subscribedToNewsletter } from 'src/components/analytics'

import {
  SmallArrow
} from 'src/components/svgs'

export const Newsletter = ({
  listId = '',
  customFields = {},
  buttonText = 'Subscribe',
  message = `We've recieved your information!`
}: {
  listId?: string
  customFields?: {}
  buttonText?: string
  message?: string
}) => {
  const [success, setSuccess] = useState(false)
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const form = e.currentTarget
    const { email } = form.elements
    subscribe(listId, email.value, customFields)
    .then(() => {
      subscribedToNewsletter(email.value)
      form.reset()
      setSuccess(true)
    })
  }
  return (
    <React.Fragment>
      {!success ? (
        <form onSubmit={e => handleSubmit(e)} className='x f newsletter pr color--white jcb aist'>
          <input aria-label='Email address' name='email' className='x color--white pt1 pb1' placeholder='Just add your email' type='email' required={true} />
          <button type='submit' className='color--white y cg p15 pa right button button--w p1 ac'>
            <SmallArrow className='db' />
          </button>
        </form>
      ): (
        <div>
          <p>{message}</p>
        </div>
      )}
    </React.Fragment>
  )
}
